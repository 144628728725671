import React, { useEffect, useState, useCallback } from "react";

import useHttp from "../store/use-http";
import { listApplicationsAndTermsAndConditions } from "../graphql/customQueries";

import ApplicationList from "../components/ApplicationList";
import { ImpulseSpinner } from "react-spinners-kit";
import classes from "./AppMain.module.css";
const AppMain = (props) => {
  const { isLoading, error, sendRequest } = useHttp();
  const [appsAndTerms, setAppsAndTerms] = useState([]);

  const getApplicationsAndTermsAndConditionsInfo = useCallback((data) => {
    const queryResult = data.listApplications.items;

    const appsAndTermsColl = [];

    for (const element of queryResult) {
      let appsAndTermsItem = [];

      appsAndTermsItem["id"] = element.id;

      appsAndTermsItem["name"] = element.name;

      appsAndTermsItem["version"] = element._version;

      appsAndTermsItem["iosMandatoryVersion"] = element.IOSMandatoryVersion;

      appsAndTermsItem["iosOptionalVersion"] = element.IOSOptionalVersion;

      appsAndTermsItem["androidMandatoryVersion"] = element.AndroidMandatoryVersion;

      appsAndTermsItem["androidOptionalVersion"] = element.AndroidOptionalVersion;

      const oldVersions = [];
      const tacIds = [];
      if (element.TermsAndConditions.items.length > 0) {
        appsAndTermsItem["currentVersion"] =
          element.TermsAndConditions.items[0].description.split(":")[1];

        appsAndTermsItem["appStatistic"] =
          element.TermsAndConditions.items[0].ApplicationUsers.items.length;

        const approvedStats =
          element.TermsAndConditions.items[0].ApplicationUsers.items.filter(
            (item) => item.approved
          ).length;
        const allStats =
          element.TermsAndConditions.items[0].ApplicationUsers.items.length;
        let rate = 0;

        if (allStats > 0) {
          rate = (approvedStats / allStats) * 100;
        }

        appsAndTermsItem["approvedRate"] = rate;

        tacIds.push(element.TermsAndConditions.items[0].id);

        for (let i = 1; i < element.TermsAndConditions.items.length; i++) {
          tacIds.push(element.TermsAndConditions.items[i].id);
          const oldVersionApprovedStats =
            element.TermsAndConditions.items[0].ApplicationUsers.items.filter(
              (item) => item.approved
            ).length;
          const oldVersionAllStats =
            element.TermsAndConditions.items[0].ApplicationUsers.items.length;
          rate = 0;
          if (allStats > 0) {
            rate = (oldVersionApprovedStats / oldVersionAllStats) * 100;
          }

          oldVersions.push({
            value:
              element.TermsAndConditions.items[i].description.split(":")[1],
            label:
              "Version " +
              element.TermsAndConditions.items[i].description.split(":")[1],
            name: element.name,
            tacId: element.TermsAndConditions.items[i].id,
            appStatistic:
              element.TermsAndConditions.items[i].ApplicationUsers.items.length,
            approvedRate: rate,
          });
        }

        appsAndTermsItem["olderVersions"] = oldVersions;

        appsAndTermsItem["tacIds"] = tacIds;
      }

      appsAndTermsColl.push(appsAndTermsItem);
    }
    setAppsAndTerms(appsAndTermsColl);
  }, []);

  useEffect(() => {
    sendRequest(
      {
        query: listApplicationsAndTermsAndConditions,
      },
      getApplicationsAndTermsAndConditionsInfo
    );
    return () => {
      setAppsAndTerms([]);
    };
  }, [sendRequest, getApplicationsAndTermsAndConditionsInfo]);

  return (
    <>
      {isLoading && (
        <div className={classes.centered}>
          <ImpulseSpinner
            size={100}
            frontColor="#f0676a"
            backColor="#e6e8f1"
            loading={isLoading}
          />
        </div>
      )}
      {!error && !isLoading && (
        <ApplicationList isLoading={isLoading} appsAndTerms={appsAndTerms} />
      )}
      {error && <p>{error}</p>}
    </>
  );
};
export default AppMain;
