import React, { useState } from "react";
const UserContext = React.createContext({
  user: null,
  onSignin: () => {},
  onSignout: () => {},
});

export const UserContextProvider = (props) => {
  const [user, setUser] = useState(null);
  const signinHandler = (userData) => {
    setUser(userData);
  };
  const signoutHandler = () => {
    console.log("signing out...");
    setUser(null);
  };

  const userContext = {
    user,
    onSignin: signinHandler,
    onSignout: signoutHandler,
  };
  return (
    <UserContext.Provider value={userContext}>
      {props.children}
    </UserContext.Provider>
  );
};
export default UserContext;
