import { useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
const ApplicationListItem = (props) => {
  const history = useHistory();
  const selectOnChange = useCallback(
    async (e) => {
      history.push({
        pathname: "/tacversiondetails",
        state: {
          appName: e.name,
          tacVersion: e.value,
          tacId: e.tacId,
        },
      });
    },
    [history]
  );
  const { app } = props;
  return (
    <li key={app.id}>
      <h3>{app.name}</h3>
      <br />
        <p>
          <strong>App Versions:</strong> 
          <br />
          {app.iosMandatoryVersion && (String("IOS Mandatory Version :"+app.iosMandatoryVersion))} {app.iosOptionalVersion && (String("IOS Optional Version :"+app.iosOptionalVersion))}
          <br />
          {app.androidMandatoryVersion && (String("Android Mandatory Version :"+app.androidMandatoryVersion))} {app.androidOptionalVersion && (String("Android Optional Version :"+app.androidOptionalVersion))}
          <Link
            className="btn primary small"
            to={{
              pathname: "/updateapplicationversion",
              state: {
                appId: app.id,
                appName: app.name,
                iosMandatoryVersion: app.iosMandatoryVersion,
                iosOptionalVersion: app.iosOptionalVersion,
                androidMandatoryVersion: app.androidMandatoryVersion,
                androidOptionalVersion: app.androidOptionalVersion,
                appVersion: app.version,
              },
            }}>
            {app.iosMandatoryVersion || app.androidMandatoryVersion
              ? "Update Application Version"
              : "Add Application Version"}
          </Link>
      </p>
      <p>
        <strong>App Key:</strong> {app.id}
      </p>
      {app.currentVersion && (
        <p>
          <strong>Current Version:</strong> {app.currentVersion}
          <Link
            to={{
              pathname: "/tacversiondetails",
              state: {
                appName: app.name,
                tacVersion: app.currentVersion,
                tacId: app.tacIds[0],
              },
            }}>
            Click for Terms and Conditions Details
          </Link>
        </p>
      )}
      {app.appStatistic > 0 && (
        <p>
          <strong>Application User Statistics: </strong> Current version
          approved by {app.approvedRate}% of total users
          <Link
            to={{
              pathname: "/appusers",
              state: {
                appId: app.id,
                appName: app.name,
              },
            }}>
            Click For User Details
          </Link>
        </p>
      )}
      {app.olderVersions && app.olderVersions.length > 0 && (
        <>
          <div className="versionInfo">
            <p>Older Versions</p>
            <Select
              className="basic-single"
              classNamePrefix="select"
              isClearable={"true"}
              isSearchable={"true"}
              name="olderversion"
              onChange={selectOnChange}
              options={app.olderVersions}
            />
          </div>
        </>
      )}

      <Link
        className="btn primary small"
        to={{
          pathname: "/newversiontac",
          state: { appId: app.id, appName: app.name },
        }}>
        Create New Version
      </Link>
    </li>
  );
};
export default ApplicationListItem;
