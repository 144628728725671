export const getApplicationLastTermsAndConditions = `query getApplicationLastTermsAndConditions($sortDirection: ModelSortDirection = DESC, $id: ID!,  $limit: Int = 1) {
        getApplication(id: $id) {
            name
            TermsAndConditions(limit: $limit, sortDirection: $sortDirection) {
                items {
                    createdAt
                    htmlString
                    description
                }
              }
            }       
    }`;

export const getApplicationForClientSide = `query getApplicationForClientSide($id: ID!, $userId: String) {
  getApplicationForClientSide(id: $id) {
    id
    name
    IOSMandatoryVersion
    IOSOptionalVersion
    AndroidMandatoryVersion
    AndroidOptionalVersion
    TermsAndConditions(sortDirection: DESC, limit: 1) {
      items {
        id
        htmlString
        ApplicationUsers(userId: { eq: $userId }) {
          items {
            id
            approved
            userInfo
          }
        }
      }
    }
  }
  `;
export const listApplicationsAndTermsAndConditions = `query listApplicationsAndTermsAndConditions {
        listApplications {
          items {
            id
            name
            IOSMandatoryVersion
            IOSOptionalVersion
            AndroidMandatoryVersion
            AndroidOptionalVersion
            _version
            TermsAndConditions(sortDirection: DESC) {
              items {
                id
                htmlString
                description
                applicationID
                ApplicationUsers {
                  items {
                    approved
                    userInfo
                  }
                }
              }
            }
          }
        }
      }`;

export const listApplicationUsers = `query listApplicationUsers ($id: ID!) {
        getApplication(id: $id) {
          ApplicationUsers(sortDirection: DESC) {
            items {
              id
              ipAddress
              approved
              userInfo
              termsAndConditionsId
            }
          }
        }
      }`;
