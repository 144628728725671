import React, { useContext } from "react";
import { Auth } from "aws-amplify";
import UserContext from "../store/user-context";
import { Link, useHistory } from "react-router-dom";
import classes from "./AppHeader.module.css";
const AppHeader = (props) => {
  const history = useHistory();
  const userCtx = useContext(UserContext);
  const { user } = userCtx;
  const logoutHandler = () => {
    Auth.signOut().then();
  };
  return (
    <>
      {user && (
        <ul className={classes.header}>
          <li onClick={() => history.push("/")}>
            <h2 className={classes.logo}>
              Simple<strong>TAC</strong>
            </h2>
          </li>
          <li>
            <Link className="btn primary" to="/new-application">
              {" "}
              Create New App{" "}
            </Link>
            <button
              className="btn secondary"
              type="button"
              onClick={logoutHandler}>
              {" "}
              Logout{" "}
            </button>
          </li>
        </ul>
      )}
    </>
  );
};

export default AppHeader;
