import { API } from "@aws-amplify/api";
import { useState } from "react";
import { updateApplication } from "../graphql/mutations";
import { useHistory } from "react-router-dom";
import FormContainer from "../components/FormContainer";
import { useLocation } from "react-router-dom";

const UpdateApplicationVersion = (props) => {
  const history = useHistory();

  const location = useLocation();
  const locationState = location.state;

  const [appName] = useState(locationState.appName);

  const [appId] = useState(locationState.appId);

  const [iosMandatoryVersionInput, setIosMandatoryVersionInput] = useState(locationState.iosMandatoryVersion);  
  const [iosOptionalVersionInput, setIosOptionalVersionInput] = useState(locationState.iosOptionalVersion);
  const [androidMandatoryVersionInput, setAndroidMandatoryVersionInput] = useState(locationState.androidMandatoryVersion);
  const [androidOptionalVersionInput, setAndroidOptionalVersionInput] = useState(locationState.androidOptionalVersion);
  const [appVersion] = useState(locationState.appVersion);
  
  const iosMandatoryVersionChangeHandler = (e) => {
    setIosMandatoryVersionInput(e.target.value);
  };

  const iosOptionalVersionChangeHandler = (e) => {
    setIosOptionalVersionInput(e.target.value);
  };

  const androidMandatoryVersionChangeHandler = (e) => {
    setAndroidMandatoryVersionInput(e.target.value);
  };

  const androidOptionalVersionChangeHandler = (e) => {
    setAndroidOptionalVersionInput(e.target.value);
  };

  const updateAppVersionHandler = async () => {
    try {
      if (iosMandatoryVersionInput && androidMandatoryVersionInput) {
        const response = await API.graphql({
          query: updateApplication,
          variables: {
            input: {
              id: appId,
              IOSMandatoryVersion: iosMandatoryVersionInput,
              IOSOptionalVersion: iosOptionalVersionInput,
              AndroidMandatoryVersion: androidMandatoryVersionInput,
              AndroidOptionalVersion: androidOptionalVersionInput,
              _version:appVersion,
            },
          },
        });
        history.push("/");
        alert("Your operation done successfully....");
        console.log("update response :", response);
      } else alert("Mandatory versions could not be empty! Please fill area...");
    } catch (ex) {
      console.log(ex);
      const error = ex.errors.map((err) => err.message).join(",");
      alert(error);
    }
  };

  const cancel = () => {
    history.push("/", {});
  };

  return (
    <FormContainer>
      <h3>Update Application Version </h3> 
      <h3> Application Name: {appName} </h3>
      <label className="marginTop-20" htmlFor="appNameInput">
          New IOS Mandatory Version{" "}
      </label>
      <input
          className="marginBottom-20"
          onChange={iosMandatoryVersionChangeHandler}
          id="iosMandatoryVersionInput"
          type="text"
          value={iosMandatoryVersionInput}></input>
      
      <label className="marginTop-20" htmlFor="appNameInput">
          New IOS Optional Version{" "}
      </label>
      <input
          className="marginBottom-20"
          onChange={iosOptionalVersionChangeHandler}
          id="iosOptionalVersionInput"
          type="text"
          value={iosOptionalVersionInput}></input>
      <label className="marginTop-20" htmlFor="appNameInput">
              New Android Mandatory Version{" "}
      </label>
      <input
              className="marginBottom-20"
              onChange={androidMandatoryVersionChangeHandler}
              id="androidMandatoryVersionInput"
              type="text"
              value={androidMandatoryVersionInput}></input>    
        <label className="marginTop-20" htmlFor="appNameInput">
                New Android Optional Version{" "}
        </label>
        <input
                className="marginBottom-20"
                onChange={androidOptionalVersionChangeHandler}
                id="androidOptionalVersionInput"
                type="text"
                value={androidOptionalVersionInput}></input>              
      <button
        className="btn primary"
        type="button"
        onClick={updateAppVersionHandler}>
        Update Versions
      </button>
      <button
        className="btn secondary marginLeft-20"
        type="button"
        onClick={cancel}>
        Cancel
      </button>
    </FormContainer>
  );
};
export default UpdateApplicationVersion;
