import { useState, useCallback } from "react";
import { API } from "@aws-amplify/api";
const useHttp = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const sendRequest = useCallback(async (requestConfig, handleData) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await API.graphql(requestConfig);
      if (response.errors) {
        throw new Error("Request failed!");
      }

      const data = await response.data;
      handleData(data);
    } catch (err) {
      setError(err.message || "Something went wrong!");
    } finally {
      setIsLoading(false);
    }
  }, []);
  return { isLoading, error, sendRequest };
};
export default useHttp;
