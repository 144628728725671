import ApplicationListItem from "./ApplicationListItem";
import classes from "./ApplicationList.module.css";
import NoApplicationItem from "../components/NoApplicationItem";
import { ImpulseSpinner } from "react-spinners-kit";
const ApplicationList = (props) => {
  const { isLoading, appsAndTerms } = props;
  if (isLoading)
    return <div className="loading"><ImpulseSpinner size={50} frontColor="#f0676a" backColor="#bdc1d4" loading={isLoading} /></div>;
  if (!appsAndTerms || appsAndTerms.length === 0) return <NoApplicationItem />;
  return (
    <ul className={classes.appCards}>
      {appsAndTerms.map((app) => {
        return <ApplicationListItem key={app.id} app={app} />;
      })}
    </ul>
  );
};
export default ApplicationList;
