/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updateApplicationUsersSetApprovedTrue = /* GraphQL */ `
  mutation UpdateApplicationUsersSetApprovedTrue(
    $input: ApproveApplicationUsersInput
  ) {
    updateApplicationUsersSetApprovedTrue(input: $input) {
      id
      userInfo
      approved
    }
  }
`;
export const createApplicationUsers = /* GraphQL */ `
  mutation CreateApplicationUsers(
    $input: CreateApplicationUsersInput!
    $condition: ModelApplicationUsersConditionInput
  ) {
    createApplicationUsers(input: $input, condition: $condition) {
      id
      ipAddress
      termsAndConditionsId
      applicationID
      userId
      userInfo
      approved
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createApplication = /* GraphQL */ `
  mutation CreateApplication(
    $input: CreateApplicationInput!
    $condition: ModelApplicationConditionInput
  ) {
    createApplication(input: $input, condition: $condition) {
      id
      name
      description
      IOSMandatoryVersion
      IOSOptionalVersion
      AndroidMandatoryVersion
      AndroidOptionalVersion
      sharedAccounts
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      ApplicationUsers {
        nextToken
        startedAt
      }
      TermsAndConditions {
        nextToken
        startedAt
      }
    }
  }
`;
export const updateApplication = /* GraphQL */ `
  mutation UpdateApplication(
    $input: UpdateApplicationInput!
    $condition: ModelApplicationConditionInput
  ) {
    updateApplication(input: $input, condition: $condition) {
      id
      name
      description
      IOSMandatoryVersion
      IOSOptionalVersion
      AndroidMandatoryVersion
      AndroidOptionalVersion
      sharedAccounts
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      ApplicationUsers {
        nextToken
        startedAt
      }
      TermsAndConditions {
        nextToken
        startedAt
      }
    }
  }
`;
export const createAccount = /* GraphQL */ `
  mutation CreateAccount(
    $input: CreateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    createAccount(input: $input, condition: $condition) {
      id
      fullname
      phoneNumber
      email
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createTermsAndConditions = /* GraphQL */ `
  mutation CreateTermsAndConditions(
    $input: CreateTermsAndConditionsInput!
    $condition: ModelTermsAndConditionsConditionInput
  ) {
    createTermsAndConditions(input: $input, condition: $condition) {
      id
      htmlString
      description
      applicationID
      _version
      _deleted
      _lastChangedAt
      updatedAt
      ApplicationUsers {
        nextToken
        startedAt
      }
      createdAt
    }
  }
`;
