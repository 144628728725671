import React, { useEffect, useContext, useState } from "react";
import { Route, Switch } from "react-router";
import { Auth, Hub } from "aws-amplify";
import "./App.css";
import "./styles/Reset.css";
import AppHeader from "./components/AppHeader";
import Signin from "./pages/Signin";
import AppMain from "./pages/AppMain";
import UserContext from "./store/user-context";
import ApproveTac from "./pages/ApproveTac";
import Signout from "./pages/Signout";
import NewVersionTac from "./pages/NewVersionTac";
import NewApplication from "./pages/NewApplication";
import TacVersionDetails from "./pages/TacVersionDetails";
import AppUsers from "./pages/AppUsers";
import { ImpulseSpinner } from "react-spinners-kit";
import { useHistory } from "react-router-dom";
import UpdateApplicationVersion from "./pages/UpdateApplicationVersion";

function App() {
  const userCtx = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [mount, setMount] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!mount) {
      setMount(true);
      const getUser = () => {
        setIsLoading(true);
        Auth.currentAuthenticatedUser()
          .then((userData) => {
            userCtx.onSignin(userData);
          })
          .catch((error) => console.log(error))
          .finally(() => setIsLoading(false));
      };
      Hub.listen(
        "auth",
        ({ payload: { event, data } }) => {
          switch (event) {
            case "signIn":
            case "cognitoHostedUI":
              getUser();
              break;
            case "signOut":
              userCtx.onSignout();
              break;
            default:
              console.log("oauth event", event);
              break;
          }
        },
        []
      );

      history.push("/signin");
      getUser();
    }
  }, [userCtx, mount, history]);

  if (isLoading)
    return (
      <div className="loading">
        <ImpulseSpinner
          size={100}
          frontColor="#f0676a"
          backColor="#e6e8f1"
          loading={isLoading}
        />
      </div>
    );

  return (
    <>
      <AppHeader />
      <Switch>
        <Route path="/" exact>
          <AppMain />
        </Route>
        <Route path="/signin" exact>
          <Signin />
        </Route>
        <Route path="/signout" exact>
          <Signout />
        </Route>
        <Route path="/approvetac" exact>
          <ApproveTac />
        </Route>
        <Route path="/newversiontac" exact>
          <NewVersionTac />
        </Route>
        <Route path="/new-application" exact>
          <NewApplication />
        </Route>
        <Route path="/tacversiondetails" exact>
          <TacVersionDetails />
        </Route>
        <Route path="/appusers" exact>
          <AppUsers />
        </Route>
        <Route path="/updateapplicationversion" exact>
          <UpdateApplicationVersion />
        </Route>
        <Route path="*">
          <p>Not found</p>
        </Route>
      </Switch>
    </>
  );
}

export default App;
