import React, { useState, useEffect, useCallback } from "react";

import "../styles/Pages.css";
import { useLocation, Link } from "react-router-dom";
import { getTermsAndConditions } from "../graphql/queries";
import { API } from "@aws-amplify/api";

import "draft-js/dist/Draft.css";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import FormContainer from "../components/FormContainer";
import htmlToDraft from 'html-to-draftjs';
import classes from "./ApproveTac.module.css";

const TacVersionDetails = (props) => {
  const [termsAndConditionsState, setTermsAndConditionsState] = useState(null);

  const location = useLocation();
  const locationState = location.state;
  const initialTacVersion = locationState.tacVersion;
  const [tacVersion, setTacVersion] = useState(initialTacVersion);

  const { tacId, appName } = locationState;

  const htmlToString = (termsAndConditions) =>{
    
    const termsAndConditionsContent= htmlToDraft(termsAndConditions);

    if (termsAndConditionsContent) {
      const contentState = ContentState.createFromBlockArray(
        termsAndConditionsContent.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      setTermsAndConditionsState(editorState);
    }
  }

  const createEmpty = useCallback(() => EditorState.createEmpty(), []);
 

  const getTermsAndConditionsInfo = useCallback(
    async (e) => {
      try {
        setTermsAndConditionsState(createEmpty);
        const tacInfo = await API.graphql({
          query: getTermsAndConditions,
          variables: { id: tacId },
        });
        htmlToString(tacInfo.data.getTermsAndConditions.htmlString);
        if (
          tacVersion !==
          tacInfo.data.getTermsAndConditions.description.split(":")[1]
        ) {
          setTacVersion(
            tacInfo.data.getTermsAndConditions.description.split(":")[1]
          );
          //alert("Terms And Conditions version number is not compatible with your version. You will redirected to main page...");
          //history.push("/");
        }
      } catch (error) {
        console.log("error occured on fetch version :", error);
      }
    },
    [tacId, tacVersion, createEmpty]
  );

  useEffect(() => {
    getTermsAndConditionsInfo();
    return () => {
      setTermsAndConditionsState(null);
      setTacVersion(null);
    };
  }, [getTermsAndConditionsInfo]);

  return (
    <FormContainer>
      <h2>
        {appName} <span>v. {tacVersion}</span>
      </h2>
      <h3>Terms and Conditions Details</h3>

      <div className={classes.previewTac}>
        {termsAndConditionsState && (
          <Editor
            editorState={termsAndConditionsState}
            toolbarClassName="hide-toolbar"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            readOnly={"true"}
          />
        )}
      <br/>
        <Link
          className="btn secondary marginTop-20 marginLeft-20"
          to={{
            pathname: "/",
          }}>
          {" "}
          Go Back{" "}
        </Link>
      </div>
    </FormContainer>
  );
};

export default TacVersionDetails;
