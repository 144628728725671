import React, { useState, useEffect, useCallback  } from "react";
import "../styles/Pages.css";
import { useLocation, Link, useHistory} from "react-router-dom";
import { API } from "@aws-amplify/api";
import "draft-js/dist/Draft.css";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import classes from "./NewVersionTac.module.css";
import { getApplicationLastTermsAndConditions } from "../graphql/customQueries";
import FormContainer from "../components/FormContainer";
import htmlToDraft from 'html-to-draftjs';

const NewVersionTac = (props) => {

  const history = useHistory();

  const location = useLocation();
  const appId = location.state.appId;
  const [appNameStr, setAppNameStr] = useState("");
  const [termsAndConditionsState, setTermsAndConditionsState] = useState(
    EditorState.createEmpty()
  );

  const [tacVersion, setTacVersion] = useState(1);

  const htmlToString = (termsAndConditions) =>{
    const termsAndConditionsContent= htmlToDraft(termsAndConditions);

    if (termsAndConditionsContent) {
      const contentState = ContentState.createFromBlockArray(
        termsAndConditionsContent.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      setTermsAndConditionsState(editorState);
    }
  }
  
  const onEditorStateChange = useCallback((editorState) => {
    setTermsAndConditionsState(editorState);
  }, []);

  const getApplicationTermsAndConditionsInfo = useCallback(async () => {
    try {
      const tacInfo = await API.graphql({
        query: getApplicationLastTermsAndConditions,
        variables: { id: appId },
      });
      if (
        tacInfo.data.getApplication.TermsAndConditions?.items &&
        tacInfo.data.getApplication.TermsAndConditions?.items.length > 0
      ) {
        htmlToString(
              tacInfo.data.getApplication.TermsAndConditions?.items[0]
                .htmlString);

        setTacVersion(
          parseInt(
            tacInfo.data.getApplication.TermsAndConditions?.items[0].description.split(
              ":"
            )[1]
          ) + 1
        );
      }
      setAppNameStr(tacInfo.data.getApplication.name);
    } catch (error) {
      console.log("error occured on fetch version :", error);
    }
  }, [appId]);

  useEffect(() => {
    if (location.state.termsAndConditions) {
      htmlToString(location.state.termsAndConditions);
      setAppNameStr(location.state.appName);
    } else {
      getApplicationTermsAndConditionsInfo().then();
    }
    if (location.state.appVersion) {
      setTacVersion(location.state.appVersion);
    }
  }, [
    appId,
    getApplicationTermsAndConditionsInfo,
    location.state.appName,
    location.state.appVersion,
    location.state.termsAndConditions,
  ]);

  const exportHTML = () => {
    return draftToHtml(convertToRaw(termsAndConditionsState.getCurrentContent()));
  };

  const onContinueClick = () => {
    const htmlStr = exportHTML();
    history.push({
      pathname: "/approvetac",
      state: {
        appId: appId,
        appName: appNameStr,
        termsAndConditions: htmlStr,
        appVersion: tacVersion,
      }},
      );
  }

  return (
    <FormContainer>
      <h2>
        {appNameStr} <span>v. {tacVersion}</span>
      </h2>
      <h3>Terms and Conditions</h3>

      <div className={classes.textEditor}>
        {termsAndConditionsState && (
          <Editor
            editorState={termsAndConditionsState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName={classes.editorClassName}
            onEditorStateChange={onEditorStateChange}
          />
        )}
      </div>

      <button
        className="btn primary marginTop-20"
        onClick={onContinueClick}>
        Continue
      </button>
      <Link
        className="btn secondary marginTop-20 marginLeft-20"
        to={{
          pathname: "/",
        }}>
        {" "}
        Cancel{" "}
      </Link>
    </FormContainer>
  );
};

export default NewVersionTac;
