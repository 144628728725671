/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getApplicationForClientSide = /* GraphQL */ `
  query GetApplicationForClientSide($id: ID!, $userId: String!) {
    getApplicationForClientSide(id: $id, userId: $userId) {
      id
      name
      description
      IOSMandatoryVersion
      IOSOptionalVersion
      AndroidMandatoryVersion
      AndroidOptionalVersion
      TermsAndConditions {
        nextToken
        startedAt
      }
      sharedAccounts
    }
  }
`;
export const syncApplicationUsers = /* GraphQL */ `
  query SyncApplicationUsers(
    $filter: ModelApplicationUsersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncApplicationUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        ipAddress
        termsAndConditionsId
        applicationID
        userId
        userInfo
        approved
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getApplication = /* GraphQL */ `
  query GetApplication($id: ID!) {
    getApplication(id: $id) {
      id
      name
      description
      IOSMandatoryVersion
      IOSOptionalVersion
      AndroidMandatoryVersion
      AndroidOptionalVersion
      sharedAccounts
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      ApplicationUsers {
        nextToken
        startedAt
      }
      TermsAndConditions {
        nextToken
        startedAt
      }
    }
  }
`;
export const listApplications = /* GraphQL */ `
  query ListApplications(
    $filter: ModelApplicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApplications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        IOSMandatoryVersion
        IOSOptionalVersion
        AndroidMandatoryVersion
        AndroidOptionalVersion
        sharedAccounts
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncApplications = /* GraphQL */ `
  query SyncApplications(
    $filter: ModelApplicationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncApplications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        IOSMandatoryVersion
        IOSOptionalVersion
        AndroidMandatoryVersion
        AndroidOptionalVersion
        sharedAccounts
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAccounts = /* GraphQL */ `
  query SyncAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAccounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        fullname
        phoneNumber
        email
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncTermsAndConditions = /* GraphQL */ `
  query SyncTermsAndConditions(
    $filter: ModelTermsAndConditionsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTermsAndConditions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        htmlString
        description
        applicationID
        _version
        _deleted
        _lastChangedAt
        updatedAt
        createdAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getTermsAndConditions = /* GraphQL */ `
  query GetTermsAndConditions($id: ID!) {
    getTermsAndConditions(id: $id) {
      id
      htmlString
      description
      applicationID
      _version
      _deleted
      _lastChangedAt
      updatedAt
      ApplicationUsers {
        nextToken
        startedAt
      }
      createdAt
    }
  }
`;
