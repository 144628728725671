import React from 'react';
import User from './User';

function UserList({ filteredUsers }) {
  const filtered = filteredUsers.map( user =>  <User key={user.id} user={user} />); 
  return (
    <div>
      {filtered}
    </div>
  );
}

export default UserList;