import { API } from "@aws-amplify/api";
import { useState } from "react";
import { createApplication } from "../graphql/mutations";
import { useHistory } from "react-router-dom";
import FormContainer from "../components/FormContainer";
const NewApplication = (props) => {
  const history = useHistory();
  const [appName, setAppName] = useState(null);
  const appNameChangeHandler = (e) => {
    setAppName(e.target.value);
  };
  const [appId, setAppId] = useState(null);
  if (appId) history.push("/newversiontac", { appId: appId });
  const createNewAppHandler = async () => {
    try {
      if (appName) {
        const response = await API.graphql({
          query: createApplication,
          variables: {
            input: {
              name: appName,
            },
          },
        });
        setAppId(response.data.createApplication.id);
      } else alert("Application Name could not be empty! Please fill area...");
    } catch (ex) {
      console.log(ex);
      const error = ex.errors.map((err) => err.message).join(",");
      alert(error);
    }
  };

  const cancelNewApp = () => {
    history.push("/", {});
  };

  return (
    <FormContainer>
      <h3>Create New App</h3>
      <label className="marginTop-20" htmlFor="appNameInput">
        Application Name{" "}
      </label>
      <input
        className="marginBottom-20"
        onChange={appNameChangeHandler}
        id="appNameInput"
        type="text"
        value={appName}></input>
      <button
        className="btn primary"
        type="button"
        onClick={createNewAppHandler}>
        Create
      </button>
      <button
        className="btn secondary marginLeft-20"
        type="button"
        onClick={cancelNewApp}>
        Cancel
      </button>
    </FormContainer>
  );
};
export default NewApplication;
