import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import UserContext from "../store/user-context";
const Signin = (props) => {
  const userCtx = useContext(UserContext);
  const { user } = userCtx;
  const history = useHistory();
  useEffect(() => {
    if (user) history.replace("/");
  }, [user, history]);

  return (
    <div className="loginContainer">
      <h2 className="logo">
        Simple<strong>TAC</strong>
      </h2>
      <button className="btn google"
        type="button"
        onClick={() => Auth.federatedSignIn({ provider: "Google" })}
      >
        
      </button>
    </div>
      
  );
};
export default Signin;
