import React, { useCallback, useState, useEffect } from "react";
import { API } from "@aws-amplify/api";
import "../styles/Pages.css";
import { EditorState, ContentState } from 'draft-js';
import { useHistory, useLocation } from "react-router-dom";
import { createTermsAndConditions } from "../graphql/mutations";
import classes from "./ApproveTac.module.css";
import FormContainer from "../components/FormContainer";
import { Editor } from "react-draft-wysiwyg";

import htmlToDraft from 'html-to-draftjs';


const ApproveTac = (props) => {
  const history = useHistory();

  const location = useLocation();
  const { appId, appName, termsAndConditions, appVersion } = location.state;

  const [tacString, setTacString] = useState(EditorState.createEmpty());

  const htmlToString = () =>{
    const termsAndConditionsContent= htmlToDraft(termsAndConditions);

    if (termsAndConditionsContent) {
      const contentState = ContentState.createFromBlockArray(
        termsAndConditionsContent.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      setTacString(editorState);
    }
  }

  useEffect(() => {
    htmlToString();    
  }, []);


  const handleSubmit = useCallback(
    async (e) => {
      const createTermsAndConditionsVariables = {
        applicationID: appId,
        htmlString: termsAndConditions,
        description: "version:" + appVersion,
      };
      try {
        await API.graphql({
          query: createTermsAndConditions,
          variables: { input: createTermsAndConditionsVariables },
        });
        history.push("/");
        alert("Your operation done successfully....");
      } catch (error) {
        alert("Something went wrong");
        console.log(error);
      }
    },
    [appVersion, appId, termsAndConditions, history]
  );

  return (
    <FormContainer>
      <h2>
        {appName} <span>v. {appVersion} Approval </span>
      </h2>
      <div className={classes.previewTac}>
        {tacString && (
          <Editor
            editorState={tacString}
            toolbarClassName='hide-toolbar'
            wrapperClassName="wrapperClassName"
          />
        )}
        <br/>
        <button className="btn primary" onClick={handleSubmit}>
          Approve
        </button>
        <button
          className="btn secondary marginLeft-20"
          onClick={() =>
            history.push({
              pathname: "/newversiontac",
              state: {
                appId: appId,
                appName: appName,
                termsAndConditions: termsAndConditions,
                appVersion: appVersion,
              },
            })
          }>
          Go Back
        </button>
      </div>
    </FormContainer>
  );
};

export default ApproveTac;
