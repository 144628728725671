import React from "react";
import { Link } from "react-router-dom";

function NoApplicationItem({ user }) {
  return (
    <>
      <p>
        {" "}
        Simple Tac'e hoş geldin. Görüntüleyebileceğimiz bir uygulama bulamadık.
        İlk Uygulamanı oluşturmak için hemen tıkla!&nbsp;&nbsp;
        <Link
          to={{
            pathname: "/new-application",
          }}>
          Create New App
        </Link>
      </p>
    </>
  );
}

export default NoApplicationItem;
