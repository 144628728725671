import React, { useState, useEffect, useCallback } from "react";

import UserList from "../components/UserList";
import Scroll from "../components/Scroll";
import { listApplicationUsers } from "../graphql/customQueries";
import { API } from "@aws-amplify/api";
import { useLocation } from "react-router-dom";

const AppUsers = (props) => {
  const [searchField, setSearchField] = useState("");

  const [userInfo, setUserInfo] = useState([]);
  const [mount, setMount] = useState(false)

  const location = useLocation();
  const appName = location.state.appName;
  const appId = location.state.appId;

  const getApplicationUsersInfo = useCallback( async () => {
    try {
      const appUsers = await API.graphql({
        query: listApplicationUsers,
        variables: {id:appId},
      });
      const userList = appUsers.data.getApplication.ApplicationUsers.items.map((user) => {
        const { fullname, email } = JSON.parse(user.userInfo);
        const { id } = user;
        const { approved } = user;
        return { fullname, email, id, approved };
      });
      setUserInfo(userList);
    } catch (error) {
      console.log("error occured on fetch version :", error);
    }
  },[appId]);

  useEffect(() => {
    if(!mount){
      setMount(true);
      getApplicationUsersInfo();
    }    
  }, [getApplicationUsersInfo, mount]);


  const filteredUsers = userInfo.filter((user) => {
    return (
        user.fullname.toLowerCase().includes(searchField.toLowerCase()) ||
        user.email.toLowerCase().includes(searchField.toLowerCase()) 
    );
  });

  const handleChange = (e) => {
    setSearchField(e.target.value);
  };

  function searchList() {
    return (
      <Scroll>
        <UserList filteredUsers={filteredUsers} />
      </Scroll>
    );
  }

  return (
    <>
      <h1>Welcome to App Users Page</h1>

      <h3>App Name: {appName} </h3>
    
      <div className="container">
        <div className="toppane">
          <section className="garamond">
            <div className="pa2">
              <input
                className="pa3 bb br3 grow b--none bg-lightest-blue ma3"
                type="search"
                placeholder="Search Users"
                onChange={handleChange}
              />
              {searchList()}
            </div>
          </section>    
        </div>
      </div>

    </>
  );
};

export default AppUsers;
