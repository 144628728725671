
import React from 'react';

function User({user}) {
	return(
		<div className="tc bg-light-green dib br3 pa3 ma2 grow bw2 shadow-5">
			<div>
				<h2>Name: {user.fullname}</h2>
				<p>Email:{user.email}</p>
				<p>Approved: {user.approved.toString()}</p>
			</div>
		</div>
	);
}

export default User;