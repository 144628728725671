import { Link } from "react-router-dom";
const Signout = (props) => {
  return (
    <>
      <p>Yine bekleriz. Gene gel olur mu?</p>
      <Link to="/signin">Tekrar giriş yap</Link>
    </>
  );
};
export default Signout;
